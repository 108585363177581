import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { format } from 'date-fns';
import Slider from 'react-slick';

import Container from 'components/base/container';

import './style.scss';

const settings = {
  focusOnSelect: true,
  centerMode: true,
  infinite: false,
  centerPadding: '0',
  slidesToShow: 5,
  slidesToScroll: 5,
  dots: true,
  arrows: false,
  initialSlide: 2,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function SectionEventPromo({ content = [], id }) {
  const [currentEvent, setCurrentEvent] = useState({});
  const [eventList, setEventList] = useState([]);
  useEffect(() => {
    if (!content.length) return;

    const modifier = () => {
      const modifiedEvent = [...content];
      const latestEvent = modifiedEvent.shift();
      setCurrentEvent(latestEvent);
      setEventList(modifiedEvent);
    };

    modifier();
  }, [content]);

  const dateFormatter = (date) => {
    if (!date) return;
    try {
      const parsedDate = date.split(' ').join('T');
      const formattedDate = format(new Date(parsedDate), 'PPP');
      return formattedDate;
    } catch (_) {
      return '';
    }
  };

  return (
    <section id={id} className="pd-home-event-promo">
      <div
        className="background-image-desktop section"
        style={{
          background: `url(${process.env.PUBLIC_URL}/home/gradient.png) no-repeat`,
          backgroundSize: 'cover',
        }}>
        <Container>
          <Row type="flex" align="middle" style={{ height: '100%' }}>
            <Col xs={24}>
              <div className="title-2 white">
                <strong>
                  <u>EVENTS &amp; PROMO</u>
                </strong>
              </div>
            </Col>
            <Col xs={24}>
              <div className="current-event pd-margin-bottom-sm">
                <div className="image-container">
                  <img src={`${currentEvent.image}`} alt="Artist" />
                </div>
              </div>
            </Col>
            <Col xs={24}>
              <Slider {...settings}>
                {eventList.length > 0 &&
                  eventList.map((event, index) => {
                    const {
                      image = '',
                      title = '',
                      startDate = '',
                      endDate = '',
                    } = event || {};
                    return (
                      <div className="event" key={index}>
                        <div className="event-image-container">
                          <img src={image} alt="Gallery" />
                        </div>
                        <div className="text-container">
                          <div className="title-3">{title}</div>
                          <div className="date">
                            {dateFormatter(startDate)} -{' '}
                            {dateFormatter(endDate)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}
